:root {
    --scrollbar: 0;
    --header-height: 54px
}

*, *::before, *::after {
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    background-color: rgba(0, 0, 0, 0);
    text-decoration: none
}
:where(button) {
    border: 0;
    background-color: rgba(0, 0, 0, 0)
}
:where(picture, svg) {
    max-inline-size: 100%;
    block-size: auto;
    height: auto;
    vertical-align: bottom;
}
:where(input, button, textarea, select) {
    font: inherit;
    color: inherit
}
.header {
    color: #000;
    font-family: YakuHanJP, "Noto Sans JP", sans-serif;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.5;
    position: sticky;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10;
}

.header * {
    box-sizing: border-box;
}
.header__bar {
    padding-left: 15px;
    height: var(--header-height);
    background-color: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    justify-content: space-between;
}
.header__logo {
    margin-right: auto;
    width: 93px
}
.header__logo__link {
    width: 93px;
}
.header__items {
    display: flex;
}
.header__btn-menu {
    display: flex;
    -moz-column-gap: 12px;
    column-gap: 12px;
    margin-right: 12px
}
.header__btn-menu svg {
    margin-inline: auto;
    width: 22px
}
.header__btn-menu .item {
    text-align: center
}
.header__btn-menu .item a {
    height: var(--header-height);
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}
.header__btn-menu .txt {
    letter-spacing: 0;
    display: inline-block;
    margin-top: 1px;
    font-size: .625rem
}
.intro-nav {
    position: relative;
    z-index: 0;
    margin-top: -20px
}
.no-history {
    z-index: unset;
    margin-top: -28px
}
.intro-nav::before {
    z-index: -1;
    margin-top: 20px;
    position: absolute;
    top: 0;
    content: "";
    display: block;
    width: 100%;
    height: 270px;
    background-color: #6eb92b
}
.intro-nav__history {
    background-color: #fff;
    display: block;
    border-radius: 6px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 52px;
    position: relative;
    transition: background-color .3s
}
.intro-nav__history:hover, .intro-nav__history:active {
    background-color: #e8f2df
}
.intro-nav__history::after {
    content: url("/assets/renewal/smp/img/icon/circle-arrow.svg");
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%)
}
.intro-nav__history-top {
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: .1em;
    background-image: url("/assets/renewal/smp/img/icon/search.svg");
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 18px 18px;
    padding-left: 23px;
    transition: background-color .3s
}
.intro-nav__history-top .count {
    font-size: 0
}
.intro-nav__history-top .num {
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-size: 1.25rem;
    color: #6eb92b;
    letter-spacing: .02em
}
.intro-nav__history-top .sm {
    font-size: .75rem
}
.intro-nav__history-top:hover, .intro-nav__history-top:active {
    background-color: #e8f2df
}
.intro-nav__history-btm {
    font-size: .8125rem;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
.intro-nav__col3-link {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    -moz-column-gap: 6px;
    column-gap: 6px;
    margin-top: 8px
}
.intro-nav__col3-link-item a {
    background-color: #fff;
    font-size: .9375rem;
    letter-spacing: .1em;
    display: block;
    border-radius: 5px;
    overflow: hidden;
    transition: background-color .3s
}
.intro-nav__col3-link-item a:hover, .intro-nav__col3-link-item a:active {
    background-color: #e8f2df
}
.intro-nav__col3-link-item a .icon {
    width: 42px;
    margin-top: 4px
}
.intro-nav__col3-link-arrow {
    background-color: #e8f2df;
    display: grid;
    place-items: center;
    height: 20px
}
.intro-nav__col3-link-arrow img {
    width: 10px
}
.intro-nav__txt-area {
    padding: 12px;
    font-size: .9375rem;
    letter-spacing: .1em;
    font-weight: 700;
    display: grid;
    place-items: center
}
.intro-nav__btm-link {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    background-color: #fff;
    letter-spacing: .1em;
    font-size: .9375rem;
    font-weight: 700;
    height: 54px;
    border-radius: 6px;
    transition: background-color .3s
}
.intro-nav__btm-link img {
    width: 35px
}
.intro-nav__btm-link:hover, .intro-nav__btm-link:active {
    background-color: #e8f2df
}
.link-btns {
    position: relative;
    z-index: 1;
    display: grid;
    gap: 5px
}
.link-btns+.link-btns {
    margin-top: 5px
}
.link-btns._top {
    margin-top: -30px
}
.link-btns.link-btns--col1 {
    grid-template-columns: repeat(1, 1fr)
}
.link-btns.link-btns--col1 .icon {
    width: 56px
}
.link-btns.link-btns--col2 {
    grid-template-columns: repeat(2, 1fr)
}
.link-btns.link-btns--col2 .icon {
    width: 42px
}
.link-btns.link-btns--col3 {
    grid-template-columns: repeat(3, 1fr)
}
.link-btns.link-btns--col3 .link-btns__item a {
    flex-direction: column;
    padding-right: 0
}
.link-btns.link-btns--col3 .link-btns__item a .icon {
    width: 33px
}
.link-btns__item a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background-color: #fff;
    font-size: 1rem;
    height: 80px;
    border-radius: 6px;
    letter-spacing: .06em;
    font-weight: 700;
    background-image: url("/assets/renewal/smp/img/icon/arrow_green_right.svg");
    background-size: 6px 10px;
    background-position: right 10px center;
    background-repeat: no-repeat;
    padding-right: 18px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .15)
}
.link-btns__item a .icon {
    width: 42px
}
.link-btns__item a:hover {
    background-color: #e8f2df
}
.link-btns__item a:active {
    background-color: #e8f2df
}
.menu-btn {
    width: 54px;
    height: 54px;
    background-color: #6eb92b
}
.menu-btn__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-column-gap: 10px;
    column-gap: 10px
}
.menu-btn .line {
    display: grid;
    row-gap: 8px;
    width: 25px;
    margin-left: 1px
}
.menu-btn .line::before, .menu-btn .line::after {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 999px;
    background-color: #fff;
    content: "";
    transition: all .3s
}
.menu-btn[aria-expanded=true] .line::before {
    transform: translateY(6px) rotate(45deg)
}
.menu-btn[aria-expanded=true] .line::after {
    transform: translateY(-5px) rotate(-45deg)
}
.menu {
    position: fixed;
    top: var(--header-height);
    left: 0;
    z-index: 5;
    overflow: auto;
    height: calc(100svh - var(--header-height));
    width: 100vw;
    background-color: #f0f0f0;
    opacity: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between
}
.menu__quick-search {
    background-color: #6eb92b;
    padding-top: 20px;
    padding-bottom: 10px
}
.menu__quick-search-top {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
    -moz-column-gap: 20px;
    column-gap: 20px
}
.menu__quick-search-txt {
    color: #fff;
    letter-spacing: .2em;
    font-size: .9375rem;
    background-image: url(/assets/renewal/smp/img/icon/search_white.svg);
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 22px 22px;
    padding-left: 32px
}
.menu__quick-search-word {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: #fff;
    border-radius: 6px;
    padding-left: 20px;
    padding-right: 5px;
    -moz-column-gap: 10px;
    column-gap: 10px;
    margin-top: 10px
}
.menu__quick-search-word input[type=text] {
    height: 100%;
    flex: 1;
    color: #292929;
    font-size: 1rem
}
.menu__quick-search-word .btn {
    flex-shrink: 0;
    width: 70px;
    height: 40px;
    display: grid;
    place-items: center;
    background-color: #6eb92b;
    color: #fff;
    border-radius: 5px
}
.menu__center {
    padding-top: 12px;
    padding-bottom: 15px;
    width: 100%;
    flex: 1
}
.menu__center-item+.menu__center-item {
    margin-top: 8px
}
.menu__center-item.open .btn-icon::after {
    opacity: 0
}
.menu__btn {
    background-color: #6eb92b;
    color: #fff;
    font-size: 1rem;
    letter-spacing: .1em;
    position: relative;
    display: flex;
    align-items: center;
    -moz-column-gap: 9px;
    column-gap: 9px;
    padding: 10px 20px;
    height: 50px;
    width: 100%;
    border-radius: 5px
}
.menu__btn .btn-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px
}
.menu__btn--acc .btn-icon::before, .menu__btn--acc .btn-icon::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background: #fff;
    border-radius: 99px
}
.menu__btn--acc .btn-icon::after {
    transform: rotate(90deg);
    transition: opacity .3s
}
.menu__btn--link {
    transition: background-color .3s
}
.menu__btn--link:hover, .menu__btn--link:active {
    background-color: #538b21
}
.menu__btn--link .btn-icon::before {
    content: "";
    background-image: url("/assets/renewal/smp/img/icon/arrow_white_right.svg");
    background-repeat: no-repeat;
    background-size: 7px 11px;
    background-position: center;
    width: 7px;
    height: 11px;
    display: block
}
.menu__btn--dark {
    background-color: #538b21;
    letter-spacing: .04em
}
.menu__btn .icon {
    width: 34px
}
.menu__center-sub-item:first-child {
    margin-top: 6px
}
.menu__center-sub-item+.menu__center-sub-item {
    margin-top: 4px
}
.menu__center-sub-item a {
    background-color: #fff;
    font-size: 1rem;
    letter-spacing: .1em;
    position: relative;

    display: flex;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    padding: 10px 20px;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    transition: background-color .3s
}
.menu__center-sub-item a:hover, .menu__center-sub-item a:active {
    background-color: #e8f2df
}
.menu__center-sub-item a .btn-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 10px;
    height: 10px
}
.menu__center-sub-item a .btn-icon::before {
    content: "";
    background-image: url("/assets/renewal/smp/img/icon/arrow_green_right.svg");
    background-repeat: no-repeat;
    background-size: 7px 11px;
    background-position: center;
    width: 7px;
    height: 11px;
    display: block
}
.menu__center-sub-item--tab a {
    -moz-column-gap: 10px;
    column-gap: 10px
}
.menu__center-sub-item--tab a::after {
    content: "";
    background-image: url("/assets/renewal/smp/img/icon/tab-link_green.svg");
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: center;
    display: block;
    width: 12px;
    height: 12px
}
.site-links {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 50px
}
.site-links__item {
    line-height: 1;
    font-size: .75rem;
    letter-spacing: 0;
    font-weight: 700;
    padding-right: 24px;
    display: flex;
    background-image: url("/assets/renewal/smp/img/icon/arrow_green_right.svg");
    background-repeat: no-repeat;
    background-size: 6px 10px;
    background-position: center right;
    color: #4b4b4b
}
.site-links__item.site-links__item--tab {
    -moz-column-gap: 10px;
    column-gap: 10px
}
.site-links__item.site-links__item--tab::after {
    content: url("/assets/renewal/smp/img/icon/tab-link_green.svg");
    display: block;
    width: 12px;
    height: 12px
}
.container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto
}
.pt30 {
    padding-top: 30px !important
}
.pb30 {
    padding-bottom: 30px !important
}
.bg-white {
    background-color: #fff
}